/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './style/scrollbar-styles.css';

const theme = createTheme({
    typography: {
        fontFamily: "NouvelR",
    },
});

const Chat = ({ chat, loadingMessage, handleChange, handleKeyPress, message, addMessage }) => {
    const chatListRef = useRef(null);
    const [fullHeightDiv, setFullHeightDiv] = useState(document.documentElement.clientHeight);
    const [headerLoading, setHeaderLoading] = useState(true);
    useEffect(() => {
        if(!headerLoading) return;
        if(document.getElementById("header").offsetHeight) {
          setHeaderLoading(false);
          setFullHeightDiv(document.documentElement.clientHeight - document.getElementById("header").offsetHeight);
        }
        
      }, [headerLoading]);
    useEffect(() => {
        if (chatListRef.current) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
        }
    }, [chat]);
    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ width: '100%', height: '100%' }} >
                <Grid item xs={12}>
                    <List ref={chatListRef} style={{ width: '100%', height: `${fullHeightDiv - 60}px`, overflowY: 'auto', marginLeft: '20px', padding: 0 }}>
                        {chat.map((c, i) =>
                            <ListItem key={i} sx={{ fontFamily: 'NouvelR', marginTop: i === 0 ? '20px' : 0 }}>
                                <Grid container>
                                    {c.from === "assistant" ?
                                        <Grid item xs={12}>
                                            <ListItem>
                                                <Avatar sx={{ bgcolor: "#000" }}>
                                                    <img src="images/Logo_renault.svg" style={{ height: "60%", width: "50%" }} alt="avatar" />
                                                </Avatar>
                                                <Typography style={{ fontFamily: "NouvelR", fontWeight: "600", marginLeft: "10px", fontSize: "20px" }}>
                                                    Renault
                                                </Typography>
                                            </ListItem>
                                            <ListItemText align='left' primary={c.msg} style={{ marginLeft: "10px" }}
                                                sx={{
                                                    '& .MuiTypography-root': {
                                                        fontWeight: "600",
                                                        fontSize: '20px',
                                                        maxWidth: '65%'
                                                    }
                                                }} />
                                        </Grid>
                                        :
                                        <Grid item xs={12} style={{flexDirection : 'row', display: 'flex'}}>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={6}>

                                                <ListItem style={{ flexDirection: 'column', alignItems: 'self-end'}}>
                                                    <Grid container justifyContent="flex-end" alignItems="center">
                                                        <Grid item>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "NouvelR",
                                                                    fontWeight: "600",
                                                                    fontSize: "20px"
                                                                }}
                                                            >
                                                                Vous
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Avatar sx={{ bgcolor: "#ecb100", marginLeft: "10px" }}>
                                                                <img src="images/account.svg" style={{ height: "50%", width: "50%" }} alt="avatar" />
                                                            </Avatar>
                                                        </Grid>
                                                    </Grid>
                                                    <ListItemText
                                                        align='right'
                                                        primary={c.msg}
                                                        sx={{
                                                            '& .MuiTypography-root': {
                                                                fontWeight: "600",
                                                                fontSize: '20px',
                                                                textAlign: "right",
                                                            }
                                                        }}
                                                    />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </ListItem>
                        )}
                    </List>
                    {loadingMessage && <div className="snippet" data-title="dot-flashing" style={{ position: 'absolute', bottom: '60px', left: '60px' }}>
                            <div className="stage">
                                <div className="dot-flashing" />
                            </div>
                        </div>
                    }
                    
                    <div style={{ display: 'flex', alignItems: 'center', height:"50px" }}>
                        <IconButton style={{ backgroundColor: "#fff", border: '1px solid #000', marginRight: '5px', marginLeft: "40px" }} onClick={() => addMessage('ME', message)} aria-label="add">
                            <img src="images/file.svg" alt="file" style={{ height: "18px" }} />
                        </IconButton>
                        <TextField
                            placeholder='Ecrivez votre message'
                            InputProps={{
                                disableUnderline: true,
                            }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: '6px 6px'
                                }
                            }}
                            disabled={loadingMessage}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            value={message}
                            fullWidth
                            style={{ border: '1px solid #000', borderRadius: '30px', marginRight: '5px' }}
                            variant="standard"
                        />
                        <IconButton style={{ backgroundColor: "#fff", border: '1px solid #000', marginRight: '5px' }} onClick={() => addMessage('ME', message)} aria-label="add">
                            <img src="images/arrow.svg" alt="arrow" style={{ height: "18px" }} />
                        </IconButton>
                        <IconButton style={{ backgroundColor: "#000", marginRight: "50px" }} onClick={() => addMessage('ME', message)} aria-label="add">
                            <img src="images/mic.svg" alt="mic" style={{ height: "18px" }} />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default Chat;
