/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import CustomImageList from "./media";
import Chat from "./Chat";
import useWebSocket from 'react-use-websocket';

const Home = () => {
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([{ from: 'assistant', msg: 'Bonjour ! Je suis votre assistant intelligent Renault. Ma mission aujourd’hui est de vous assister dans la découverte de notre tout nouveau modèle électrique, la déjà mythique R5 ! Que vous soyez curieux ou déjà fan, de quoi voulez vous discuter ?' }]);
  const { sendMessage, lastMessage } = useWebSocket('wss://hyf10szwji.execute-api.eu-west-3.amazonaws.com/production');
  const [loadingMessage, setloadingMessage] = useState(false);
  const [listeMessages, setListeMessage] = useState([])
  const [listeMedia, setListeMedia] = useState([
    {
      url: 'https://renault-waib.s3.eu-west-3.amazonaws.com/1e798ff336.gif',
      title: 'img 1',

    },
    {
      url: 'https://renault-waib.s3.eu-west-3.amazonaws.com/r5-pass.gif',
      title: 'img 2',
    },
    {
      url: 'https://renault-waib.s3.eu-west-3.amazonaws.com/couleur-bleu.jpg',
      title: 'img 3',
    },

  ])
  const addMessage = useCallback((from, msg) => {
    if (msg.trim() === '') return;
    sendMessage(JSON.stringify({ "action": "user-message", "content": msg }));
    setloadingMessage(true);
    setChat(prevChat => [...prevChat, { from, msg }]);
    setMessage('');
  }, [sendMessage]);

  const onClickMedia = useCallback((msg) => {
    sendMessage(JSON.stringify({ "action": "user-message", "content": msg }));
    setChat(prevChat => [...prevChat, { from:'ME', msg }]);
    setloadingMessage(true);
    setMessage('');
  }, [sendMessage]);

  useEffect(() => {
    if (lastMessage) {
      try {
        const message = JSON.parse(lastMessage.data);
        if (message.content && message?.content !== "[DONE]") {
          listeMessages.push(message.content)
        }
        if (message.content === undefined) {
          setListeMedia(message?.media)
        }
        if (message?.content === "[DONE]") {
          setChat(prevChat => [...prevChat, { from: "assistant", msg: listeMessages.join('') }]);

          setListeMessage([]);
          setloadingMessage(false);
        }
      } catch (error) {
        console.error('Erreur lors de l\'analyse du message WebSocket:', error);
      }
    }
  }, [lastMessage]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      addMessage('ME', message);
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }} >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Chat chat={chat} loadingMessage={loadingMessage} handleChange={handleChange} handleKeyPress={handleKeyPress} message={message} addMessage={addMessage} />
        </Grid>
        <Grid item xs={6}>
          <CustomImageList onClickMedia={onClickMedia} listeMedia={listeMedia} />
        </Grid>
      </Grid>
    </Box>

  );
};

export default Home;
