/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function CustomImageList({ onClickMedia, listeMedia }) {
  const ListeLength = listeMedia?.length;
  const [fullHeightDiv, setFullHeightDiv] = useState(document.documentElement.clientHeight);
  const [headerLoading, setHeaderLoading] = useState(true);
  const calculateColsAndRows = (index) => {
    if (ListeLength === 1) {
      return { cols: 2, rows: 1, height: `${fullHeightDiv}px` };
    }
    else if (ListeLength === 2) {
      return { cols: 2, rows: 1, height: `${(fullHeightDiv/2) - 5}px` };
    } else if (ListeLength === 3 && index === 0) {
      return { cols: 2, rows: 1, height: `${(fullHeightDiv/2) - 5}px` };
    } else if (ListeLength === 3 && (index === 1 || index === 2)) {
      return { cols: 1, rows: 1, height: `${(fullHeightDiv/2) - 5}px` };
    } else if (ListeLength === 4) {
      return { cols: 1, rows: 1, height: `${(fullHeightDiv/2) - 5}px` };
    } else if (ListeLength === 5) {
      return { cols: 2, rows: 1, height: `${(fullHeightDiv/5)- 5}px` };
    } else {
      return { cols: 1, rows: 1, height: `${(fullHeightDiv/4) - 5}px` };
    }
  };
  useEffect(() => {
    if(!headerLoading) return;
    if(document.getElementById("header").offsetHeight) {
      setHeaderLoading(false);
      setFullHeightDiv(document.documentElement.clientHeight - document.getElementById("header").offsetHeight);
    }
    
  }, [headerLoading]);
  return (
    <ImageList gap={5} style={{ margin: "0px" }}>
      {listeMedia?.map((item, index) => {
        const { cols, rows, height } = calculateColsAndRows(index);
        return (
          <ImageListItem key={item.url} cols={cols} rows={rows}>
            <img
              src={item.url}
              alt={item.title}
              style={{ height: height, cursor: item?.action !== undefined ? 'pointer' : '' }}
              onClick={() => item?.action !== undefined ? onClickMedia(item?.action) : ''}
              loading="lazy"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}
